html {
  height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
  background-color:#0B0B0B;
}

#root {
  width: 100%;
  height: 100vh;
}

/* width */
::-webkit-scrollbar {
  width: 0.7rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1A1A1A;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 0.35rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-moz-selection { /* Code for Firefox */
  color: #40280b;
  background:#F49E37;
}

::selection {
  color: #40280b;
  background: #F49E37;
}